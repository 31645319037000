import { css, Global } from '@emotion/core'
import React from 'react'
import { Helmet } from 'react-helmet'
import statisticImage from '../assets/statistic.png'

const globalStyle = css`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  html,
  button,
  input,
  textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Roboto, system-ui, -apple-system, Segoe UI, Ubuntu, Cantarell, Noto Sans, ans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  }
`

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link rel="icon" href={statisticImage} />

      <meta name="title" content="Maker Traffic - Ads platform for makers" />
      <meta
        name="description"
        content="Advertise each others maker projects on your website and get instant traffic! Win-win situation! This is a new unique alternative to Google AdSense."
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://makertraffic.com/" />
      <meta property="og:title" content="Maker Traffic - Ads platform for makers" />
      <meta
        property="og:description"
        content="Advertise each others maker projects on your website and get instant traffic! Win-win situation! This is a new unique alternative to Google AdSense."
      />
      <meta property="og:image" content={statisticImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://makertraffic.com/" />
      <meta property="twitter:title" content="Maker Traffic - Ads platform for makers" />
      <meta
        property="twitter:description"
        content="Advertise each others maker projects on your website and get instant traffic! Win-win situation! This is a new unique alternative to Google AdSense."
      />
      <meta property="twitter:image" content={statisticImage} />

      <link href="https://fonts.googleapis.com/css?family=Roboto:400,700" rel="stylesheet" />
    </Helmet>
    <Global styles={globalStyle} />
    {children}
  </>
)

export default Layout
