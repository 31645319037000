import { css } from '@emotion/core'
import { Link } from 'gatsby'

const styles = css`
  text-decoration: none;
`

const StyledLink = ({ to, children }) => (
  <Link css={styles} to={to}>
    {children}
  </Link>
)

export default StyledLink
