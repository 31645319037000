import { css } from '@emotion/core'
import { useStore } from 'laco-react'
import React, { useEffect } from 'react'
import { AccountStore, getAccount, logout } from '../stores/account'
import Link from './Link'

const container = css`
  margin: 0 auto;
  max-width: 72rem;
`

const listContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
`

const list = css`
  padding: 0 2rem;
`

const navLink = css`
  display-inline: block;
  font-size: 24px;
  font-weight: bold;
  color: #3525e6;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

const specificList = css`
  display: flex;
`

const Navbar = () => {
  useEffect(() => getAccount(), [])
  const state = useStore(AccountStore)
  return (
    <nav css={container}>
      <ul css={listContainer}>
        <li css={list}>
          <Link to="/">
            <h1 css={navLink}>MakerTraffic.com</h1>
          </Link>
        </li>
        <li css={specificList}>
          {!state.authenticated && (
            <div css={list}>
              <Link to="/register">
                <span css={navLink}>Register</span>
              </Link>
            </div>
          )}
          {!state.authenticated && (
            <div css={list}>
              <Link to="/login">
                <span css={navLink}>Login</span>
              </Link>
            </div>
          )}
          {state.authenticated && window.location.pathname === '/' && (
            <div css={list}>
              <Link to="/dashboard">
                <span css={navLink}>Dashboard</span>
              </Link>
            </div>
          )}
          {state.authenticated && (
            <div css={list}>
              <span css={navLink} onClick={logout}>
                Logout
              </span>
            </div>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
