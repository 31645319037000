import { Store } from 'laco'

export const AccountStore = new Store({ authenticated: false }, 'AccountStore')

export const domain = process.env.NODE_ENV === 'production' ? 'https://api.makertraffic.com' : 'http://localhost:3000'

export const getAccount = () => {
  fetch(`${domain}/account`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
  })
    .then(res => res.json())
    .then(() => {
      AccountStore.set(() => ({ authenticated: true }), 'Get account')
    })
    .catch(e => AccountStore.set(() => ({ authenticated: false }), 'Get account'))
}

export const logout = () => {
  fetch(`${domain}/logout`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
  })
    .then(() => {
      AccountStore.set(() => ({ authenticated: false }), 'Logout')
      window.location.href = window.location.origin
    })
    .catch(e => AccountStore.set(() => ({ authenticated: true }), 'Logout'))
}
