import React from 'react'

const Arrow = props => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M11.322 10L7.744 6.423a.833.833 0 1 1 1.179-1.179l4.166 4.167a.833.833 0 0 1 0 1.178l-4.166 4.167a.833.833 0 1 1-1.179-1.179L11.322 10z"
      fill="#051033"
      fillOpacity={0.01}
    />
    <mask id="prefix__c" maskUnits="userSpaceOnUse" x={7} y={5} width={7} height={10}>
      <path
        d="M11.322 10L7.744 6.423a.833.833 0 1 1 1.179-1.179l4.166 4.167a.833.833 0 0 1 0 1.178l-4.166 4.167a.833.833 0 1 1-1.179-1.179L11.322 10z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix__c)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0h20v20H0V0z" fill={props.color} />
    </g>
  </svg>
)

export default Arrow
