import React from 'react'

const User = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4-4 1.8-4 4zm4 2c1.095 0 2-.905 2-2s-.905-2-2-2-2 .905-2 2 .905 2 2 2zm0 4c-5.515 0-9 2.275-9 7h18c0-4.725-3.485-7-9-7zm-6.648 5c.803-1.99 2.99-3 6.648-3 3.658 0 5.845 1.01 6.648 3H5.352z"
      fill={props.color}
      fillOpacity={0.01}
    />
    <mask id="prefix__b" maskUnits="userSpaceOnUse" x={3} y={4} width={18} height={17}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8c0 2.2 1.8 4 4 4s4-1.8 4-4-1.8-4-4-4-4 1.8-4 4zm4 2c1.095 0 2-.905 2-2s-.905-2-2-2-2 .905-2 2 .905 2 2 2zm0 4c-5.515 0-9 2.275-9 7h18c0-4.725-3.485-7-9-7zm-6.648 5c.803-1.99 2.99-3 6.648-3 3.658 0 5.845 1.01 6.648 3H5.352z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix__b)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0h24v24H0V0z" fill={props.color} />
    </g>
  </svg>
)

export default User
