import React from 'react'

const Email = props => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.917 3.333h14.166c.69 0 1.25.56 1.25 1.25v10.834c0 .69-.56 1.25-1.25 1.25H2.917c-.69 0-1.25-.56-1.25-1.25V5.009a.85.85 0 0 1 0-.02v-.406c0-.69.56-1.25 1.25-1.25zm.416 3.224V15h13.334V6.557l-6.205 4.136a.833.833 0 0 1-.924 0L3.333 6.557zM15.998 5L10 8.998 4.002 5h11.996z"
      fill="#051033"
      fillOpacity={0.01}
    />
    <mask id="prefix__a" maskUnits="userSpaceOnUse" x={1} y={3} width={18} height={14}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.917 3.333h14.166c.69 0 1.25.56 1.25 1.25v10.834c0 .69-.56 1.25-1.25 1.25H2.917c-.69 0-1.25-.56-1.25-1.25V5.009a.85.85 0 0 1 0-.02v-.406c0-.69.56-1.25 1.25-1.25zm.416 3.224V15h13.334V6.557l-6.205 4.136a.833.833 0 0 1-.924 0L3.333 6.557zM15.998 5L10 8.998 4.002 5h11.996z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#prefix__a)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0h20v20H0V0z" fill={props.color} />
    </g>
  </svg>
)

export default Email
