import { css } from '@emotion/core'
import React, { useState } from 'react'

const inputContainer = (focus, big) => css`
  padding: ${big ? '0.75' : '0.5'}rem;
  display: flex;
  align-items: center;
  border: 2px solid ${focus ? '#3525e6' : '#e1e4e8'};
  transition: 200ms ease;
  border-radius: 4px;
  outline: none;
  &::placeholder {
    color: #a6aebc;
  }
  &:focus {
    border-color: ;
  }
`
const input = big => css`
  font-size: ${big ? '18' : '14'}px;
  width: 100%;
  border: none;
  transition: 200ms border ease;
  outline: none;
  &::placeholder {
    color: #a6aebc;
  }
`

const label = css`
  display: inline-block;
  margin-bottom: 0.25rem;
  color: #8a94a6;
`

const errorStyle = css`
  margin-top: 1rem;
  text-align: center;
  color: #3525e6;
`

const Field = ({ title, placeholder, big, children, value, onChange, onBlur, error }) => {
  const [focus, setFocus] = useState(false)
  const normalizedTitle = title
    .split(' ')
    .join('')
    .toLowerCase()
  return (
    <div>
      {title && (
        <label htmlFor={normalizedTitle} css={label}>
          {title}
        </label>
      )}
      <div css={inputContainer(focus, big)}>
        <input
          id={normalizedTitle}
          css={input(big)}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={() => setFocus(true)}
          onBlur={e => {
            setFocus(false)
            onBlur(e)
          }}
        />
        {children}
      </div>
      {error && <div css={errorStyle}>{error[0].toUpperCase() + error.slice(1)}</div>}
    </div>
  )
}

export default Field
