import styled from '@emotion/styled'
import {
  alignItems,
  display,
  flexDirection,
  flexWrap,
  justifyContent,
  maxWidth,
  space,
  textAlign,
  width,
} from 'styled-system'

export default styled.div`
  ${space}
  ${display}
  ${alignItems}
  ${flexDirection}
  ${justifyContent}
  ${textAlign}
  ${flexWrap}
  ${width}
  ${maxWidth}
`
