import { css } from '@emotion/core'
import React from 'react'
import { IconArrow } from '../icons'

const container = css`
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #e1e4e8;
  border-radius: 4px;
  box-shadow: 2px 4px 4px rgba(10, 31, 68, 0.1);
`

const items = active => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid #e1e4e8;
  background-color: ${active ? '#e6e6ff47' : 'inherit'};
  &:hover {
    cursor: pointer;
    background-color: #e6e6ff47;
  }
`

const List = ({ data, activeId, handleItem }) => {
  const content = data.map((project, idx) => (
    <li key={project.id} css={items(idx === activeId)} onClick={() => handleItem(idx)}>
      <span>{project.name}</span>
      {idx === activeId && <IconArrow color="#182C4F" />}
    </li>
  ))
  return content.length ? <ul css={container}>{content}</ul> : null
}

export default List
