/* eslint-disable no-alert */
import { css } from '@emotion/core';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Box, Field, Layout, List, Navbar, Textarea } from '../components';
import { domain } from '../stores/account';

const container = css`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 2rem;
  max-width: 76rem;
  padding: 0 2rem;
`

const card = css`
  width: 42%;
  height: 100%;
  margin-bottom: 4rem;
  padding: 2rem;
  box-shadow: 0px 18px 18px rgba(10, 31, 68, 0.12), 0px 0px 12px rgba(10, 31, 68, 0.1);
  border-radius: 4px;
`

const h2 = css`
  font-size: 32px;
  font-weight: 500;
`

const newProjectButton = css`
  padding: 0.55rem 1rem;
  border-radius: 4px;
  border: 2px solid #e1e4e8;
  background-color: white;
  font-size: 14px;
  font-weight: 500;
  transition: 200ms ease;
  &:hover {
    cursor: pointer;
    border-color: #3525e6;
  }
`

const deleteProjectButton = css`
  padding: 0.55rem 1rem;
  border-radius: 4px;
  border: 2px solid #f03d3d;
  background-color: white;
  color: #f03d3d;
  font-size: 14px;
  font-weight: 500;
  transition: 200ms ease;
  &:hover {
    cursor: pointer;
    background-color: #f03d3d;
    color: white;
  }
`

const statsNumbers = css`
  margin-right: 0.5rem;
  color: #4d3df7;
  font-size: 72px;
`

const statsText = css`
  margin-bottom: 0.25rem;
  color: #8888fc;
`

const previewAd = css`
  display: block;
  margin: 2rem auto;
  width: 320px;
  height: 144px;
  border-radius: 4px;
  box-shadow: 0 8px 24px rgba(32, 43, 54, 0.12);
  text-decoration: none;
  color: white;
  background: linear-gradient(120deg, #4d3df7 0%, #8888fc 100%);
  &:after {
    position: absolute;
    border-bottom-left-radius: 2px;
    content: 'Ad by MakerTraffic';
    margin-top: -18px;
    font-family: sans-serif;
    font-size: 10px;
    background: rgba(0, 0, 0, 0.12);
    padding: 4px 6px;
    color: rgba(255, 255, 255, 0.75);
  }
`

const adTitle = css`
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 40%;
  font-size: 32px;
`

const adDescription = css`
  font-weight: 500;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 60%;
`

const Dashboard = () => {
  const [state, setState] = useState({
    activeId: 0,
    projects: [],
  })

  useEffect(() => {
    fetch(`${domain}/projects`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
    })
      .then(res => res.json())
      .then(data => setState({ ...state, projects: data }))
      .catch(e => alert('Seems like you are unauthorized.. Please login again..'))
  }, [])

  const initialValues = { newproject: '', description: '', linktowebsite: '' }

  const activeProject = state.projects[state.activeId]
  const initialValuesActive = activeProject
    ? {
        projectname: activeProject.name,
        description: activeProject.description,
        linktowebsite: activeProject.link,
      }
    : null

  return (
    <Layout>
      <Helmet>
        <title>Maker Traffic - Dashboard</title>
      </Helmet>
      <Navbar />
      <div css={container}>
        <div css={card}>
          <h2 css={h2}>Projects</h2>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              try {
                const payload = {
                  name: values.newproject,
                  description: values.description,
                  link: values.linktowebsite,
                }
                const result = await fetch(`${domain}/projects`, {
                  method: 'POST',
                  mode: 'cors',
                  cache: 'no-cache',
                  credentials: 'include',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(payload),
                })

                if (result.ok) {
                  const newProject = await result.json()
                  setState({ ...state, projects: [...state.projects, newProject] })
                  resetForm()
                } else {
                  alert("Something went wrong.. Perhaps you're not logged in? Session expired?")
                }
              } catch (error) {
                console.log(error)
                alert('Some error happened..')
              }

              setSubmitting(false)
            }}
            validationSchema={Yup.object().shape({
              newproject: Yup.string().required(),
              description: Yup.string().required(),
              linktowebsite: Yup.string()
                .url()
                .required(),
            })}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {props => {
              const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props
              return (
                <form onSubmit={handleSubmit}>
                  <Box my="1rem">
                    <Field
                      title="New project"
                      placeholder="Enter a new project name"
                      value={values.newproject}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.newproject}
                    />
                  </Box>
                  <Textarea
                    title="Description"
                    placeholder="Enter a description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.description}
                  />
                  <Field
                    title="Link to website"
                    placeholder="Enter an URL to advertisement"
                    value={values.linktowebsite}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.linktowebsite}
                  />
                  <Box mt="1rem">
                    <button css={newProjectButton} type="button" disabled={isSubmitting} onClick={handleSubmit}>
                      Add project
                    </button>
                  </Box>
                </form>
              )
            }}
          </Formik>
          <Box mt="2rem">
            <List
              data={state.projects}
              activeId={state.activeId}
              handleItem={idx => setState({ ...state, activeId: idx })}
            />
          </Box>
        </div>
        {!!state.projects.length && (
          <div css={card}>
            <h2 css={h2}>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" justifyContent="center" alignItems="flex-end" width="50%">
                  <span css={statsNumbers}>{activeProject.views}</span>
                  <span css={statsText}>views</span>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="flex-end" width="50%">
                  <span css={statsNumbers}>{activeProject.clicks}</span>
                  <span css={statsText}>clicks</span>
                </Box>
              </Box>
            </h2>
            <Formik
              enableReinitialize
              initialValues={initialValuesActive}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                try {
                  const payload = {
                    name: values.projectname,
                    description: values.description,
                    link: values.linktowebsite,
                  }
                  const result = await fetch(`${domain}/projects/${activeProject.id}`, {
                    method: 'PUT',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                  })

                  if (result.ok) {
                    alert('Your project has been updated successfully')
                    const newProjects = state.projects
                    newProjects[state.activeId] = { ...newProjects[state.activeId], ...payload }
                    setState({ ...state, projects: [...newProjects] })
                  } else {
                    alert("Something went wrong.. Perhaps you're not logged in? Session expired?")
                  }
                } catch (error) {
                  console.log(error)
                  alert('Some error happened..')
                }

                setSubmitting(false)
              }}
              validationSchema={Yup.object().shape({
                projectname: Yup.string().required(),
                description: Yup.string().required(),
                linktowebsite: Yup.string()
                  .url()
                  .required(),
              })}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {props => {
                const { values, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props
                return (
                  <form onSubmit={handleSubmit}>
                    <Box my="1rem">
                      <Field
                        title="Project name"
                        placeholder="Enter a new project name"
                        value={values.projectname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.projectname}
                      />
                    </Box>
                    <Textarea
                      title="Description"
                      placeholder="Enter a description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.description}
                    />
                    <Field
                      title="Link to website"
                      placeholder="Enter an URL to advertisement"
                      value={values.linktowebsite}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.linktowebsite}
                    />

                    <Box mt="1rem">
                      <Field title="Project token" value={activeProject.id} onBlur={handleBlur} />
                    </Box>

                    <a css={previewAd} href={activeProject.link} target="_blank" rel="noopener noreferrer">
                      <h1 css={adTitle}>{activeProject.name}</h1>
                      <h2 css={adDescription}>{activeProject.description}</h2>
                    </a>
                    <Box display="flex" justifyContent="space-between">
                      <button css={newProjectButton} type="button" disabled={isSubmitting} onClick={handleSubmit}>
                        Publish changes
                      </button>
                      <button
                        type="button"
                        css={deleteProjectButton}
                        onClick={async () => {
                          const confirmed = window.confirm(
                            "You're about to delete your project! Are you sure about this?"
                          )
                          if (confirmed) {
                            try {
                              const result = await fetch(`${domain}/projects/${activeProject.id}`, {
                                method: 'DELETE',
                                mode: 'cors',
                                cache: 'no-cache',
                                credentials: 'include',
                              })
                              if (result.ok) {
                                const newProjects = state.projects
                                newProjects.splice(state.activeId, 1)

                                setState({ activeId: 0, projects: [...newProjects] })
                              } else {
                                alert("Something went wrong.. Perhaps you're not logged in? Session expired?")
                              }
                            } catch (error) {
                              console.log(error)
                              alert('Some error happened..')
                            }
                          }
                        }}
                      >
                        Delete project
                      </button>
                    </Box>
                  </form>
                )
              }}
            </Formik>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Dashboard
